@keyframes scaleInCenter {
  0% {
    transform: scale(1%) translate(0%, 0%);
  }
  90% {
    transform: scale(108%);
  }
  100% {
    transform: scale(100%) translate(0%, 0%);
  }
}
@keyframes intoDarknes {
  0% {
    background-color: #32375411;
  }
  100% {
    background-color: #42444d88;
  }
}

/* Modal.css */
.modal-overlay {
  animation: 0.3s ease-in 0s 1 intoDarknes;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--modaloverlaybackground);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

.modal-content {
  animation: 0.15s ease-in 0s 1 scaleInCenter;
  background-color: var(--modalcontentcolor); /*#fdecff;*/
  color: var(--textcolor); /*#6e2f7e;*/
  transition: width 2s;
  padding: 10px 10px 10px 10px;
  border-radius: 15px;
  position: relative;
  min-width: 40%;
  max-width: 90%;
  overflow-y: auto;
  min-height: 20%;
  max-height: 90%;
  margin-top: -5vh;

  /* box-shadow: inset 0px 0px 10px 5px var(--shadowcolor); */
  box-shadow: 0 0 50px 10px var(--shadowcolor);
}
.modal-content.open {
  width: 90%;
}
