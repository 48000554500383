/* Spinner.css */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: rgb(76, 74, 159);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: inline-block;

  animation: spin 1s linear infinite;
}
